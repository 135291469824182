import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../../context/UserContext";

export default function UserRequireAuth({ children }: { children: JSX.Element }) {
    const { user } = useUser();
    const location = useLocation();

    if (!user) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <>{children}</>
}