import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import UsersContoller from "../../api/admin/users";
import { toast } from "react-toastify";
import Area from "../../components/admin/Area";
import Header from "../../components/admin/users/Header";
import useAdminUser from "../../hooks/useAdminUser";

export default function EditNote() {
    const { id } = useParams();
    const { user, setUser } = useAdminUser(Number(id));
    const [note, setNote] = useState("");

    const updateNote = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await UsersContoller.updateNote(Number(id), note);

        if (response.error) return toast(response.message);

        setUser(response.user);

        toast("Заметка обновлена");
    }, [id, note, setUser]);

    if (!user) return null;

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Заметка по пользователю
            </div>

            <Header user={user} />

            <form className="dinamic-content -cmr-users -other-pages-content " onSubmit={updateNote}>
                <div className="-profile-setting mt40">
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <Area title="Введите заметку" placeholder="Введите текст заметки" state={note} setState={setNote} />

                            <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
}