import { useState } from "react";
import { User as UserType } from "../types";
import UsersContoller from "../api/admin/users";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";

export default function useAdminUser(id: number) {
    const [user, setUser] = useState<UserType | null>(null);

    const getUser = useCallback(async (id: number) => {
        const response = await UsersContoller.getUser(id);

        if (response.error) return toast(response.message);

        setUser(response.user);
    }, []);

    useEffect(() => {
        if (id === undefined || id === null) return;

        getUser(Number(id));
    }, [id, getUser]);

    return { user, setUser };
}