import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UsersContoller from "../../api/admin/users";
import { toast } from "react-toastify";
import Header from "../../components/admin/users/Header";
import useAdminUser from "../../hooks/useAdminUser";

export default function EditError() {
    const { id } = useParams();
    const { user, setUser } = useAdminUser(Number(id));
    const [msg, setMsg] = useState("");
    const [selectedOption, setSelectedOption] = useState("default");
    const [isErrorShow, setIsErrorShow] = useState(false);

    useEffect(() => {
        if (!user) return;

        setMsg(user.errorMsg);
        setSelectedOption(user.errorType);
        setIsErrorShow(user.errorShow);
    }, [user]);

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };

    const updateError = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await UsersContoller.updateError(Number(id), msg, selectedOption, isErrorShow);

        if (response.error) return toast(response.message);

        setUser(response.user);

        toast("Ошибка обновлена");
    }, [id, msg, setUser, selectedOption, isErrorShow]);

    if (!user) return null;

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Ошибка на главном экране
            </div>

            <Header user={user} />

            <form className="dinamic-content -cmr-users -other-pages-content " onSubmit={updateError}>
                <div className="-profile-setting mt40">
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="second-others-inner-header" style={{ marginTop: "0" }}>
                                Ошибка на главном экране
                            </div>

                            <div className="switch-2fa-wrap mt20" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                <div className="checkbox-item">
                                    <input type="radio" id="1727058150250-parametr1" name="1727058150250-parametr" value="default" checked={selectedOption === "default"} onChange={handleOptionChange} />
                                    <label htmlFor="1727058150250-parametr1" className="-yellow-info">Стандарная</label>
                                </div>
                                <div className="checkbox-item mt10">
                                    <input type="radio" id="1727058150250-parametr2" name="1727058150250-parametr" value="custom" checked={selectedOption === "custom"} onChange={handleOptionChange} />
                                    <label htmlFor="1727058150250-parametr2" className="-purple-info">Индивидуальная <span className="-blue-info">(в поле ниже)</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-wrap -valid-input">
                                <textarea placeholder="Введите текст ошибки на главном экране" value={msg} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMsg(event.target.value)} disabled={selectedOption === "default"} />
                            </div>

                            <div className="switch-2fa-wrap mt20" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                                <div className="checkbox-item">
                                    <input type="checkbox" id="1727058150250-parametr3" name="1727058150250-parametr" checked={!isErrorShow} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsErrorShow(!event.target.checked)} />
                                    <label htmlFor="1727058150250-parametr3" className="-blue-info">Скрыть ошибку</label>
                                </div>
                            </div>

                            <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
}