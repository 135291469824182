import { useParams } from "react-router-dom";
import Header from "../../components/admin/users/Header";
import useAdminUser from "../../hooks/useAdminUser";
import VerifPending from "../../components/admin/users/VerifPending";

export default function EditVerif() {
    const { id } = useParams();
    const { user, setUser } = useAdminUser(Number(id));

    if (!user) return null;

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Верификация
            </div>

            <Header user={user} />

            <VerifPending verification={user.verifications.find(item => item.status === "pending")} setUser={setUser} />
        </div>
    </section>
}