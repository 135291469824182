import upload from "../../../../assets/img/verif/upload.png";
import cancel from "../../../../assets/img/cancel.svg";
import { ChangeEvent, ReactNode, RefObject } from "react";

interface UploadProps {
    file: File | null;
    handleFileClick: () => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    fileInputRef: RefObject<HTMLInputElement>;
    removeFile: () => void;
    children: ReactNode
}

export default function PhotoContainer({ file, handleFileClick, handleFileChange, fileInputRef, removeFile, children }: UploadProps) {
    return <>
        {!file && <label className="drag" htmlFor="passport" onClick={handleFileClick}>
            <div className="gg" >
                <i className="fa fa-cloud-upload upload-icon">
                    <img src={upload} alt="" />
                </i>

                {children}
            </div>
        </label>}

        {file && (
            <label className="drag" htmlFor="passport">
                <img
                    src={URL.createObjectURL(file)}
                    alt=""
                    className="-show-with-uploaded-photo"
                    style={{ width: "100%", height: "100%" }}
                />

                <div className="remove" onClick={removeFile} style={{ top: "6px", right: "-38px", left: "auto", background: "#e21b1b" }}>
                    <img src={cancel} alt="" style={{ width: "15px" }} />
                </div>
            </label>

        )}

        <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
        />
    </>
}