import logo from "../../assets/img/logo.png";

export default function Header() {
    return <header className="sc-izQAGL hZwTPo">
        <div className="sc-jBeCGk MEyUJ">
            <div className="sc-gtJxSR bkYVxR">
                <div className="flex items-center">
                    <div className="wallet-logo">
                        <div className="img-wrap">
                            <img src={logo} alt="" />
                        </div>
                        <span>CoinWallet</span>
                    </div>

                </div>

            </div>
        </div>
    </header>
}