import http from ".";
import { BankTransactionErrorType, DepositErrorType, DepositResponseType, SendTransactionErrorType, SendTransactionResponseType } from "../types";

const send = async (userId: number, address: string, memo: string, amount: string, currency: string): Promise<SendTransactionResponseType | SendTransactionErrorType> => {
    try {
        const { data } = await http.post(`/transactions/${userId}`, { address, memo, amount, currency });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const bank = async (userId: number, account: string, swift_bic: string, name_surname: string, address: string, amount: string): Promise<SendTransactionResponseType | BankTransactionErrorType> => {
    try {
        const { data } = await http.post(`/transactions/${userId}/bank`, { account, swift_bic, name_surname, address, amount });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const deposit = async (userId: number, amount: string, network: string): Promise<DepositResponseType | DepositErrorType> => {
    try {
        const { data } = await http.post(`/transactions/${userId}/deposit`, { amount, network });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
}

const TransactionsController = {
    send,
    deposit,
    bank
}

export default TransactionsController;