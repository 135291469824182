import logo from "../../../assets/img/logo.png"

export default function FormContainer({ children }: { children: React.ReactNode }) {
    return <div className="wallet-inner-block -registration">
        <div className="top-part-nav">
            <div className="flex items-center">
                <div className="wallet-logo">
                    <div className="img-wrap">
                        <img src={logo} alt="" />
                    </div>
                    <span>CoinWallet</span>
                </div>

            </div>
        </div>

        {children}
    </div>
} 