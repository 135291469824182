import Container from "../../components/private/Container";
import Tabs from "../../components/private/Tabs";
import Footer from "../../components/private/Footer";
import TabHome from "../../components/private/TabHome";
import { useState } from "react";
import TabHistory from "../../components/private/TabHistory";

export default function Dashboard() {
    const [activeTab, setActiveTab] = useState("home");

    return <Container>
        <div className="w-full h-full relative z-9">
            <div className="w-full h-full">
                <div className="wallet-main-wrap">
                    <div className="wallet-inner-block">

                        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

                        {activeTab === "home" && <TabHome setActiveTab={setActiveTab} />}

                        {activeTab === "history" && <TabHistory />}
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    </Container>
}