import { useEffect, useState } from "react";
import SettingsController from "../../api/admin/settings";
import { toast } from "react-toastify";
import Input from "../../components/admin/Input";
import Area from "../../components/admin/Area";

export default function Settings() {
    const [address, setAddress] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [errorMsgMainScreen, setErrorMsgMainScreen] = useState("");
    const [selectedNetwork, setSelectedNetwork] = useState('TRC20');
    const [adresses, setAdresses] = useState({ TRC20: "", BEP20: "" });

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedNetwork(event.target.value);

        setAddress(adresses[event.target.value as "TRC20" | "BEP20"]);
    };

    const getSettings = async () => {
        const response = await SettingsController.getSettings();

        if (response.error) return toast(response.message);

        setAdresses({
            TRC20: response.settings.address,
            BEP20: response.settings.addressBep
        })

        setAddress(response.settings.address);

        setErrorMsg(response.settings.errorMsg);
        setErrorMsgMainScreen(response.settings.errorMsgMainScreen);
    }

    const submitWalletSettings = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await SettingsController.updateWalletSettings(address, selectedNetwork);

        if (response.error) return toast(response.message);

        return toast(response.message);
    }

    const submitErrorSettings = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await SettingsController.updateErrorSettings(errorMsg);

        if (response.error) return toast(response.message);

        return toast(response.message);
    }

    const submitErrorMainScreen = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await SettingsController.updateErrorMainScreenSettings(errorMsgMainScreen);

        if (response.error) return toast(response.message);

        return toast(response.message);
    }

    useEffect(() => {
        getSettings();
    }, []);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="dinamic-inner-header mb40">
                <div>
                    <div>⚠️ ВНИМАНИЕ! Лучше всего вносить изменения вне пика активности.</div>
                </div>
            </div>
            <div className="main-header-of-page">
                Настройки
            </div>

            <div className="dinamic-content -cmr-users -other-pages-content ">
                <form className="-profile-setting mt40" onSubmit={submitWalletSettings}>
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="second-others-inner-header" style={{ marginTop: "0" }}>
                                Данные кошелька
                            </div>

                            {/* <div className="input-wrap  -valid-input">
                                <Input state={address} setState={setAddress} placeholder="" title="Адрес" />
                            </div>
                            <div className="input-wrap  -valid-input">
                                <Input state={network} setState={setNetwork} placeholder="" title="Network" />
                            </div> */}
                            <div className="input-wrap">
                                <div className="signature-for-input header-of-input">
                                    Network
                                </div>
                                <select value={selectedNetwork} onChange={handleSelectChange} required>
                                    <option value="TRC20">TRC20</option>
                                    <option value="BEP20">BEP20</option>
                                </select>
                            </div>

                            <div className="input-wrap  -valid-input">
                                <Input state={address} setState={setAddress} placeholder="" title="Адрес" />
                            </div>
                            <button className="-save-btn mt20" type="submit" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </form>
                <form className="-profile-setting mt40" onSubmit={submitErrorMainScreen}>
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="input-wrap -valid-input">
                                <Area title="Стандартная ошибка на главном экране" placeholder="Введите текст стандартной ошибки на главном экране" state={errorMsgMainScreen} setState={setErrorMsgMainScreen} />
                            </div>
                            <button className="-save-btn mt20" type="submit" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </form>

                <form className="-profile-setting mt40" onSubmit={submitErrorSettings}>
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <div className="input-wrap -valid-input">
                                <Area title="Стандартная ошибка при выводе" placeholder="Введите текст стандартной ошибки при выводе USDT" state={errorMsg} setState={setErrorMsg} />
                            </div>
                            <button className="-save-btn mt20" type="submit" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </section>
}