import { createContext, useContext, useEffect, useState } from "react";
import AdminController from "../api/admin/auth";
import { AdminContextType } from "../types";
import { toast } from "react-toastify";

const AdminContext = createContext<AdminContextType>(null!);

export function AdminProvider({ children }: { children: React.ReactNode }) {
    const [admin, setAdmin] = useState<boolean>(false);

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        const response = await AdminController.checkAuth();

        if (response.error) {
            return
        }

        setAdmin(true);
    };

    const signIn = async (login: string, password: string) => {
        const response = await AdminController.signIn(login, password);

        if (!response.error) {
            setAdmin(true);
        }

        return response;
    };

    const signOut = async () => {
        const response = await AdminController.signOut();

        if (response.error) return toast(response.message);

        setAdmin(false);

        return response;
    };

    return <AdminContext.Provider value={{ admin, signIn, signOut }}>{children}</AdminContext.Provider>;
}

export function useAdmin() {
    return useContext(AdminContext);
}
