import $ from "jquery";
import cancel from "../../../assets/img/cancel.svg";
import send from "../../../assets/img/send.svg";
import { useUser } from "../../../context/UserContext";
import { useState } from "react";
import TransactionsController from "../../../api/transactions";
import Input from "../../public/auth/Input";

export default function SendModal() {
    const { user, setUser } = useUser();
    const [inputs, setInputs] = useState({ address: "", memo: "memo", amount: "" });
    const [messages, setMessages] = useState({ address: "", memo: "memo", amount: "", status: "" });
    const [selectedCurrency, setSelectedCurrency] = useState('TRC20');

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCurrency(event.target.value);
    };

    if (!user) return null;

    const handleSend = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        setMessages({ address: "", memo: "memo", amount: "", status: "" });

        const response = await TransactionsController.send(user.id, inputs.address, inputs.memo, inputs.amount, selectedCurrency);

        if (response.messages) setMessages(response.messages);

        if (response.user) setUser(response.user);

        if (response.success) handleClose();
    }

    const handleClose = () => {
        setInputs({ address: "", memo: "memo", amount: "" });
        setMessages({ address: "", memo: "memo", amount: "", status: "" });

        $(".modal-wrap.-send-ton").removeClass("-open");
    }

    return <div className="modal-wrap -small -send-ton">
        <div className="close-modal-wrap">
            <button className="close-modal" type="button" onClick={handleClose}>
                <div className="txt-btn"><span>Close</span>
                    <div className="circle-txt-btn">
                        <img src={cancel} alt="" />
                    </div>
                </div>
            </button>
        </div>
        <div className="modal-inner">
            <div className="up-part-of-modal">
                <img src={send} alt="" />
                <span>Send USDT</span>
            </div>
            <form className="inner-info-of-modal" onSubmit={handleSend}>
                <div className="header-of-block">
                    Fill in the data for sending
                </div>

                <Input label="Recipient's address" placeholder="Enter USDT address..." required={false} type="text" state={inputs.address} onChange={(value: string) => setInputs({ ...inputs, address: value })} message={messages.address} />

                {/* <Input label="Recipient's memo" placeholder="Enter USDT memo..." required={false} type="text" state={inputs.memo} onChange={(value: string) => setInputs({ ...inputs, memo: value })} message={messages.memo} /> */}

                <div className="input-wrap">
                    <div className="signature-for-input">
                        Network
                    </div>
                    <select value={selectedCurrency} onChange={handleSelectChange} required>
                        <option value="TRC20">TRX (TRC20)</option>
                        <option value="ERC20">ETH (ERC20)</option>
                        <option value="BSC">BSC (BEP20)</option>
                        <option value="TON">TON (TON)</option>
                    </select>

                </div>

                <Input label="Amount of USDT to send" placeholder="0.00" required={false} type="text" state={inputs.amount} onChange={(value: string) => setInputs({ ...inputs, amount: value })} message={messages.amount} />

                <div className="amoult-with-max">
                    <div className="avalieble-now">
                        Available <span>{user.balance.toFixed(2)} USDT</span>
                    </div>
                    <button className="setup-max" type="button" onClick={() => setInputs({ ...inputs, amount: user.balance.toFixed(2) })}>
                        send max
                    </button>
                </div>

                {messages.status && <div style={{ marginTop: "20px", textAlign: "center" }}>{messages.status}</div>}

                <button className="btn-s mt24" type="submit" style={{ display: "block", width: "100%" }}>Send USDT</button>
                <button className="btn-s -cancel close-btn mt8" type="button" style={{ display: "block", width: "100%" }} onClick={handleClose}>Cancel</button>
                <div className="transaction-error">

                    <div className="value-error">
                        The transaction is considered successful after 10 confirmations in the network.<br />From 5 minutes to 1 hour.
                    </div>

                </div>
            </form>

        </div>
    </div>
}