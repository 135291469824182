import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UsersContoller from "../../api/admin/users";
import { toast } from "react-toastify";
import Input from "../../components/admin/Input";
import Header from "../../components/admin/users/Header";
import useAdminUser from "../../hooks/useAdminUser";

export default function EditUser() {
    const { id } = useParams();
    const { user, setUser } = useAdminUser(Number(id));
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        setFirstName(user?.firstname || "");
        setLastName(user?.lastname || "");
        setEmail(user?.email || "");
        setPhone(user?.phone || "");
        setPassword(user?.password || "");
    }, [user]);

    const updateUser = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const response = await UsersContoller.updateUser(Number(id), firstName, lastName, email, phone, password);

        if (response.error) return toast(response.message);

        setUser(response.user);

        toast("Профиль обновлен");
    }, [id, firstName, lastName, email, phone, password, setUser]);

    if (!user) return null;

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Ред. профиль
            </div>

            <Header user={user} />

            <form className="dinamic-content -cmr-users -other-pages-content" onSubmit={updateUser}>
                <div className="-profile-setting mt40">
                    <div className="right-part-of-secondary-pages">
                        <div className="dark-block-content -full-bg">
                            <Input title="Имя" state={firstName} setState={setFirstName} placeholder="Имя" />
                            <Input title="Фамилия" state={lastName} setState={setLastName} placeholder="Фамилия" />
                            <Input title="Email" state={email} setState={setEmail} placeholder="Email" />
                            <Input title="Номер телефона" state={phone} setState={setPhone} placeholder="Номер телефона" />
                            <Input title="Новый пароль" state={password} setState={setPassword} placeholder="Новый пароль" />

                            <button type="submit" className="-save-btn mt20" style={{ marginTop: "30px" }}>Обновить</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </section>
}