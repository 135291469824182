import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { User } from "../../../types";

export function filterByInput(users: User[], search: string) {
    return users.filter((user) => {
        return user.id?.toString()?.toLowerCase().includes(search) || user.email?.toString()?.toLowerCase().includes(search) || user.firstname?.toString()?.toLowerCase().includes(search) || user.lastname?.toString()?.toLowerCase().includes(search) || user.phone?.toString()?.toLowerCase().includes(search);
    });
}

export default function Checkboxes({ users, allUsers, filteredUsers, setFilteredUsers }: { users: User[], allUsers: User[], filteredUsers: User[], setFilteredUsers: (users: User[]) => void }) {
    const [search, setSearch] = useState("");

    const [filterLengths, setFilterLengths] = useState({
        req_withdrawal: 0,
        req_deposit: 0,
        req_verif: 0,
        has_deposit: 0,
        hasnt_deposit: 0,
        has_verif: 0,
        hasnt_verif: 0,
    });

    const [checkboxStates, setCheckboxStates] = useState({
        req_withdrawal: false,
        req_deposit: false,
        req_verif: false,
        has_deposit: false,
        hasnt_deposit: false,
        has_verif: false,
        hasnt_verif: false,
    });

    const resetCheckboxes = useCallback(() => {
        setCheckboxStates(prevCheckboxStates => {
            const newCheckboxStates = { ...prevCheckboxStates };

            Object.keys(newCheckboxStates).forEach(k => {
                newCheckboxStates[k as keyof typeof checkboxStates] = false;
            });
            return newCheckboxStates;
        });
    }, []);

    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>, key: "req_withdrawal" | "req_deposit" | "req_verif" | "has_deposit" | "hasnt_deposit" | "has_verif" | "hasnt_verif") => {
        resetCheckboxes();

        setCheckboxStates(checkboxStates => ({ ...checkboxStates, [key]: event.target.checked }));

        const filteredByInput = filterByInput(users, search);

        if (!event.target.checked) return setFilteredUsers(filteredByInput);

        if (key === "req_withdrawal") {
            const filteredByKey = filteredByInput.filter((user) => user.withdrawals.find(withdrawal => withdrawal.status === "pending") || user.banks.find(bank => bank.status === "pending"));
            setFilteredUsers(filteredByKey);
        }

        if (key === "req_deposit") {
            const filteredByKey = filteredByInput.filter((user) => user.deposits.find(deposit => deposit.status === "pending"));
            setFilteredUsers(filteredByKey);
        }

        if (key === "req_verif") {
            const filteredByKey = filteredByInput.filter((user) => user.verifications.find(verification => verification.status === "pending"));
            setFilteredUsers(filteredByKey);
        }

        if (key === "has_deposit") {
            const filteredByKey = filteredByInput.filter((user) => user.deposits.find(deposit => deposit.status === "completed"));
            setFilteredUsers(filteredByKey);
        }

        if (key === "hasnt_deposit") {
            const filteredByKey = filteredByInput.filter((user) => !user.deposits.find(deposit => deposit.status === "completed"));
            setFilteredUsers(filteredByKey);
        }

        if (key === "has_verif") {
            const filteredByKey = filteredByInput.filter((user) => user.verifications.find(verification => verification.status === "completed"));
            setFilteredUsers(filteredByKey);
        }

        if (key === "hasnt_verif") {
            const filteredByKey = filteredByInput.filter((user) => !user.verifications.find(verification => verification.status === "completed") && !user.verifications.find(verification => verification.status === "pending"));
            setFilteredUsers(filteredByKey);
        }
    }

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value.toLowerCase();

        setSearch(search);

        const filteredByInput = filterByInput(users, search);

        setFilteredUsers(filteredByInput);

        resetCheckboxes();
    }

    useEffect(() => {
        resetCheckboxes();
        setSearch("");
    }, [allUsers, resetCheckboxes]);

    useEffect(() => {
        function rewriteFilterLengths() {
            const filteredByInput = filterByInput(users, search);
            const req_withdrawal = filteredByInput.filter((user) => user.withdrawals.find(withdrawal => withdrawal.status === "pending") || user.banks.find(bank => bank.status === "pending")).length;
            const req_deposit = filteredByInput.filter((user) => user.deposits.find(deposit => deposit.status === "pending")).length;
            const req_verif = filteredByInput.filter((user) => user.verifications.find(verification => verification.status === "pending")).length;
            const has_deposit = filteredByInput.filter((user) => user.deposits.find(deposit => deposit.status === "completed")).length;
            const hasnt_deposit = filteredByInput.filter((user) => !user.deposits.find(deposit => deposit.status === "completed")).length;
            const has_verif = filteredByInput.filter((user) => user.verifications.find(verification => verification.status === "completed")).length;
            const hasnt_verif = filteredByInput.filter((user) => !user.verifications.find(verification => verification.status === "completed") && !user.verifications.find(verification => verification.status === "pending")).length;

            setFilterLengths({
                req_withdrawal,
                req_deposit,
                req_verif,
                has_deposit,
                hasnt_deposit,
                has_verif,
                hasnt_verif,
            });
        }

        rewriteFilterLengths();
    }, [search, users]);

    return <>
        <div className="search-part">
            <div className="input-wrap">
                <input type="text" placeholder="ID / Email / Имя / Телефон" onChange={onChangeInput} value={search} />
            </div>
            <button className="search-btn">
                <div className="icon-div search"></div>
            </button>
        </div>
        <div className="filter-checkbox">
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders0" onChange={(event) => onChangeCheckbox(event, "req_deposit")} checked={checkboxStates.req_deposit} />
                <label htmlFor="addtomoders0">Запрос на депозит<span className="">{filterLengths.req_deposit}</span> </label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders1" onChange={(event) => onChangeCheckbox(event, "req_withdrawal")} checked={checkboxStates.req_withdrawal} />
                <label htmlFor="addtomoders1">Требуют вывод<span className="-yellow-info">{filterLengths.req_withdrawal}</span> </label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders2" onChange={(event) => onChangeCheckbox(event, "req_verif")} checked={checkboxStates.req_verif} />
                <label htmlFor="addtomoders2">Требуют верификацию<span className="-yellow-info">{filterLengths.req_verif}</span> </label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders3" onChange={(event) => onChangeCheckbox(event, "has_deposit")} checked={checkboxStates.has_deposit} />
                <label htmlFor="addtomoders3">Есть депозит<span className="-green-info">{filterLengths.has_deposit}</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders4" onChange={(event) => onChangeCheckbox(event, "hasnt_deposit")} checked={checkboxStates.hasnt_deposit} />
                <label htmlFor="addtomoders4">Нет депозита<span className="-red-info">{filterLengths.hasnt_deposit}</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders5" onChange={(event) => onChangeCheckbox(event, "has_verif")} checked={checkboxStates.has_verif} />
                <label htmlFor="addtomoders5">Верифицированы<span className="-green-info">{filterLengths.has_verif}</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
                <input type="checkbox" id="addtomoders6" onChange={(event) => onChangeCheckbox(event, "hasnt_verif")} checked={checkboxStates.hasnt_verif} />
                <label htmlFor="addtomoders6">Без верификации<span className="-red-info">{filterLengths.hasnt_verif}</span></label>
            </div>
            <div className="checkbox-item -crm-checkbox-item">
            </div>
        </div>
        <div className="dinamic-inner-header" style={{ fontSize: "14px" }}>
            <span>Найдено по фильтру</span><span>{filteredUsers.length}</span>
        </div>
    </>
}