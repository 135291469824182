import { Bank, Deposit, Withdrawal } from "../../types";

export default function Transactions({ transactions }: { transactions: (Deposit | Withdrawal | Bank)[] }) {
    return <>
        <div className="header-of-block">
            Transaction history:
        </div>
        <div className="transaction-history-list">
            {transactions.length === 0 && <div className="signature">No transactions</div>}

            {transactions.map((transaction, index) => (
                <div className="transaction" key={index}>
                    <div className="transaction-inner">
                        <div className="left-part-transaction">
                            <div className={`icon-transaction ${transaction.type === "withdrawal" ? "-send" : transaction.type === "deposit" ? "-receive" : "-to-bank"}`}></div>
                            <div className="wrap-left-transaction">
                                <span className="signature">
                                    {transaction.type === "withdrawal" ? "Send" : transaction.type === "bank" ? "Send to bank" : "Deposit"} {transaction.status === "pending" ? <span>Pending...</span> : ""}
                                </span>
                                <span className="adres">
                                    <span>to</span> {transaction.type === "bank" ? transaction.account : transaction.address}
                                </span>
                            </div>
                        </div>
                        <div className="right-part-transaction">
                            <span className="signature">
                                {new Date(transaction.created_at).toLocaleDateString("ru-RU")}
                            </span>
                            <div className={`value-amoult ${(transaction.type === "deposit" && transaction.status !== "pending") ? "-green" : ""}`}>
                                {(transaction.type === "withdrawal" || transaction.type === "bank") ? "-" : "+"} {transaction.amount} <span>USDT</span>
                            </div>
                        </div>
                    </div>
                    {transaction.status === "rejected" && <div className="transaction-error">
                        <div className="signature -error">
                            Alert!
                        </div>
                        <div className="value-error">
                            {(transaction.type === "withdrawal" || transaction.type === "bank") ? transaction.msg : "Отклонено"}
                        </div>
                    </div>}
                </div>
            ))}
        </div>
    </>
}