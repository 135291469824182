import http from ".";
import { ChangePasswordResponseType, VerifErrorResponseType, VerifSuccessResponseType } from "../types";

const changePassword = async (userId: number, password: string, confirmPassword: string): Promise<ChangePasswordResponseType> => {
    try {
        const { data } = await http.put(`/users/${userId}/password`, {
            password,
            confirmPassword,
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const verif = async (userId: number, fullName: string, birthday: string, filePassport: File, fileSelfie: File): Promise<VerifSuccessResponseType | VerifErrorResponseType> => {
    try {
        const formData = new FormData();
        formData.append("fullName", fullName);
        formData.append("birthday", birthday);
        formData.append("filePassport", filePassport);
        formData.append("fileSelfie", fileSelfie);

        const { data } = await http.post(`/users/${userId}/verif`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const UsersControllers = {
    changePassword,
    verif
};

export default UsersControllers;