import { toast } from "react-toastify";
import VerifController from "../../../api/admin/verifications";
import { User, Verification } from "../../../types";
import { saveImage } from "../../../utils";

export default function VerifPending({ verification, setUser }: { verification: Verification | undefined, setUser: (user: User) => void }) {
    if (!verification) return null;

    const handleVerif = async (status: "completed" | "rejected") => {
        if (!verification) return;

        const response = await VerifController.handlePendingVerif(verification.id, status);

        if (response.error) return toast(response.message);

        setUser(response.user);

        toast("Транзакция верификации обновлена");
    };

    return <div className="dinamic-content -other-pages-content ">

        <div className="-profile-setting -verify-page mt40">

            <div className="left-part-right-part-of-secondary-pages">
                <div className="dark-block-content -full-bg">
                    <div className="others-inner-header">
                        Заявка на верификацию
                    </div>

                    <div className="inline-inputs-wrap">
                        {/* <!-- Добавлять класс -error / -valid-input к input-wrap --> */}
                        <div className="input-wrap -valid-input">
                            <div className="header-of-input">
                                ФИО
                            </div>
                            <div className="current-data">
                                <span className="-yellow-info">{verification.full_name}</span>
                            </div>
                        </div>

                    </div>
                    <div className="input-wrap -valid-input">
                        <div className="header-of-input">
                            Дата рождения
                        </div>
                        <div className="current-data">
                            <span className="-yellow-info">{new Date(verification.birthday).toLocaleDateString()}</span>
                        </div>
                    </div>
                    <div className="low-text-for-dark-block accent-low-text-for-dark-block">
                        Фото паспорта и селфи с паспортом
                    </div>
                    <div className="upload-photo-with-examle-photo-wrap">
                        <div className="upload-photo-wrap -was-added-photo">
                            <div className="data-for-photo-with-uploaded-photo">
                                <div className="uploaded-photo">
                                    <img src={`/images/${verification.photo_passport}`} alt="" className="-show-with-uploaded-photo" />
                                    {/* <img src="/img/" alt="" className="-hide-with-uploaded-photo" /> */}
                                </div>
                            </div>
                            <button className="btn-more-s -show-with-uploaded-photo" onClick={() => saveImage(process.env.REACT_APP_SERVER_URL + `/images/${verification.photo_passport}`)}>
                                <div className="icon-div upload-file"></div>
                                Скачать фото
                            </button>
                        </div>
                        {/* <!-- Добавлять класс -was-added-photo к upload-photo-wrap при добавленном фото --> */}
                        <div className="upload-photo-wrap -was-added-photo">
                            <div className="data-for-photo-with-uploaded-photo">
                                <div className="uploaded-photo">
                                    <img src={`/images/${verification.photo_selfie}`} alt="" className="-show-with-uploaded-photo" />
                                    {/* <img src="/img/" alt="" className="-hide-with-uploaded-photo" /> */}
                                </div>
                            </div>
                            <button className="btn-more-s -show-with-uploaded-photo" onClick={() => saveImage(process.env.REACT_APP_SERVER_URL + `/images/${verification.photo_selfie}`)}>
                                <div className="icon-div upload-file"></div>
                                Скачать фото
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <button className="btn-s-users -big-btn -green-btn-users mt20" onClick={handleVerif.bind(null, "completed")}>
            Подтвердить
        </button>
        <button className="btn-s-users -big-btn -red-btn-users mt20" onClick={handleVerif.bind(null, "rejected")}>
            Отклонить
        </button>

    </div>
}