import http from ".";
import { ErrorResponseType, UserResponseType } from "../../types";

const addWithdrawal = async (id: number, date: string, amount: string, address: string, memo: string, status: "completed" | "rejectedDefault" | "rejectedCustom", msg?: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.post(`/admin/users/${id}/withdrawal`, { date, amount, address, memo, status, msg });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
}
    ;
const addBank = async (id: number, date: string, amount: string, account: string, swift_bic: string, name_surname: string, address: string, status: "completed" | "rejectedDefault" | "rejectedCustom", msg?: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.post(`/admin/users/${id}/bank`, { date, amount, account, swift_bic, name_surname, address, status, msg });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const handlePendingWithdrawal = async (id: number, status: "completed" | "rejectedDefault" | "rejectedCustom", type: string, msg?: string): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/withdrawal/${id}`, { status, msg, type });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const cancelAction = async (id: number, type: "withdrawal" | "bank" | "deposit"): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/cancel/${id}`, { type });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const WithdrawalsController = {
    addWithdrawal,
    handlePendingWithdrawal,
    addBank,
    cancelAction,
}

export default WithdrawalsController;