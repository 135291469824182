import { Link, useLocation } from "react-router-dom";
import { User as UserType } from "../../../types";
import { formatDate } from "../../../utils";
import { toast } from "react-toastify";
import UsersContoller from "../../../api/admin/users";

export default function User({ user, setAllUsers }: { user: UserType, setAllUsers?: React.Dispatch<React.SetStateAction<UserType[]>> }) {
    const location = useLocation();

    const ban = async () => {
        const response = await UsersContoller.banUser(user.id);

        if (response.error) return toast(response.message);

        toast("Игрок заблокирован");

        if (setAllUsers) setAllUsers(response.users);
    };

    const unban = async () => {
        const response = await UsersContoller.unbanUser(user.id);

        if (response.error) return toast(response.message);

        toast("Игрок разблокирован");

        if (setAllUsers) setAllUsers(response.users);
    };

    return <div className="user-item-of-list active">

        <div className="up-part-of-user-info">
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>ID</span>
                    <span className="-blue-info">{user.id}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Баланс USDT</span>
                    <span className="-green-info">{user.balance}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Email</span>
                    <span>{user.email}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Имя</span>
                    <span>{user.firstname}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Фамилия</span>
                    <span>{user.lastname}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Номер телефона</span>
                    <span>{user.phone}</span>
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Аккаунт создан</span>
                    <span>{formatDate(user.created_at)}</span>
                </div>
            </div>
        </div>
        <div className="down-part-of-user-info">
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Нарисовать транзакции</span>
                    <span className="-green-info">{user.deposits.filter(deposit => deposit.status === "completed").length}</span>
                    {location.pathname !== `/admp/users/${user.id}/transactions` && <Link to={`/admp/users/${user.id}/transactions`} className="btn-s-users">Открыть</Link>}
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Запрос на депозит</span>
                    <span className="-blue-info">{user.deposits.filter(deposit => deposit.status === "pending").length}</span>
                    {location.pathname !== `/admp/users/${user.id}/deposits` && <Link to={`/admp/users/${user.id}/deposits`} className="btn-s-users">Открыть</Link>}
                </div>
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Запрос на вывод</span>
                    <span className="-yellow-info">{user.withdrawals.filter(withdrawal => withdrawal.status === "pending").length + user.banks.filter(bank => bank.status === "pending").length}</span>
                </div>
                {location.pathname !== `/admp/users/${user.id}/withdrawals` && <Link to={`/admp/users/${user.id}/withdrawals`} className="btn-s-users">Открыть</Link>}
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Верификация</span>
                    {user.verifications.find(verification => verification.status === "pending") && <span className="-yellow-info">Требует</span>}

                    {user.verifications.find(verification => verification.status === "completed") && <span className="-green-info">Верифицирован</span>}

                    {(!user.verifications.find(verification => verification.status === "completed") && !user.verifications.find(verification => verification.status === "pending")) && <span className="-red-info">Не верифицирован</span>}

                    {location.pathname !== `/admp/users/${user.id}/verif` && <Link to={`/admp/users/${user.id}/verif`} className="btn-s-users">Просмотреть</Link>}
                </div>
            </div>

            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Ред. профиль</span>
                </div>
                {location.pathname !== `/admp/users/${user.id}` && <Link to={`/admp/users/${user.id}`} className="btn-s-users">Открыть</Link>}

                {user.banned ?
                    <button className="btn-s-users mt20" onClick={unban}>
                        Разблокировать игрока
                    </button> :
                    <button className="btn-s-users mt20 -red-btn-users" onClick={ban}>
                        Заблокировать игрока
                    </button>
                }
            </div>
            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Ошибка на главном экране</span>
                    <span className={`${!user.errorShow ? "-blue-info" : user.errorType === "default" ? "-yellow-info" : "-purple-info"}`}>{!user.errorShow ? "Скрыта" : user.errorType === "default" ? "Стандартная" : "Индивидуальная"}</span>
                    <Link to={`/admp/users/${user.id}/error`} className="btn-s-users">Изменить</Link>
                </div>
            </div>

            <div className="parameters-of-user">
                <div className="parametr-of-user">
                    <span>Заметка по пользователю</span>
                    <span className="-blue-info">{user.note}</span>
                    <Link to={`/admp/users/${user.id}/note`} className="btn-s-users">Изменить</Link>
                </div>
            </div>
        </div>
    </div>
}