import { useEffect, useState } from "react";
import { User } from "../types";

export default function useCheckNeededVerif(user: User | null) {
    const [neededVerif, setNeededVerif] = useState(false);

    useEffect(() => {
        if (user) {
            const neededVerif = Boolean(!user.verifications.find(verification => verification.status === "pending" || verification.status === "completed"));

            setNeededVerif(neededVerif);
        }
    }, [user]);

    return { neededVerif };
}