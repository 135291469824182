import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { User, UserContextType } from "../types";
import UsersAuthController from "../api/auth";

const UserContext = createContext<UserContextType>(null!);

export function UserProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<User | null>(null);

    const checkAuth = useCallback(async () => {
        const response = await UsersAuthController.checkAuth();

        if (response.error) {
            return
        }

        setUser(response.user);
    }, []);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    return <UserContext.Provider value={{ user, setUser, checkAuth }}>{children}</UserContext.Provider>;
}

export function useUser() {
    return useContext(UserContext);
}
