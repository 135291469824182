import { useCallback, useState } from "react";
import { User as UserType } from "../../types";
import User from "../../components/admin/users/User";
import Filters from "../../components/admin/users/Filters";
import Pagination from "../../components/admin/Pagination";
import { Link } from "react-router-dom";
import { useFetchUsers } from "../../hooks/useFetchUsers";

export default function Users() {
    const [users, setUsers] = useState<UserType[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<UserType[]>([]);
    const [paginatedUsers, setPaginatedUsers] = useState<UserType[]>([]);
    const [bannedUsers, setBannedUsers] = useState<UserType[]>([]);

    const getUsers = useCallback((allUsers: UserType[]) => {
        const unbannedUsers = allUsers.filter(user => user.banned === false)

        setUsers(unbannedUsers);

        setFilteredUsers(unbannedUsers);

        setBannedUsers(allUsers.filter(user => user.banned === true));
    }, [setBannedUsers]);

    const { allUsers, setAllUsers } = useFetchUsers(getUsers);

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Пользователи
            </div>
            <div className="up-part-of-dinamic">
                <div className="switch-tab">
                    <div className="item-of-switch active">
                        <span>Все пользователи</span>
                    </div>

                </div>
                <div className="button-part">
                    <Link to="/admp/banned" className="btn-s-gradient">
                        <span>Заблокированные {` `}</span>
                        <span>{bannedUsers.length}</span>
                    </Link>
                </div>
            </div>
            <div className="dinamic-content -cmr-users -other-pages-content">
                <div className="dinamic-inner-header">
                    <span>Найдено</span><span>{users.length}</span>
                </div>
                <div className="filtration-wrap">
                    <Filters users={users} filteredUsers={filteredUsers} setFilteredUsers={setFilteredUsers} allUsers={allUsers} />
                </div>
                <div className="list-of-users">
                    {paginatedUsers.map(user => {
                        return <User user={user} key={user.id} setAllUsers={setAllUsers} />
                    })}
                </div>

                <Pagination filteredUsers={filteredUsers} setPaginatedUsers={setPaginatedUsers} />
            </div>
        </div>
    </section>
}