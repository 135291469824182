export default function Input({ label, placeholder, required, type, state, onChange, className, message }: { label: string, placeholder: string, required: boolean, type: string, state: string, onChange: any, className?: string, message: string }) {
    return <div className={`input-wrap ${className}`}>
        <div className="signature-for-input">
            {label} <span>*</span>
        </div>

        <input type={type} required={required} placeholder={placeholder} value={state} onChange={(event) => onChange(event.target.value)} />

        {message && <div className="error-msg" >{message}</div>}
    </div>
}