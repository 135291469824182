import http from ".";
import { ErrorResponseType, UserResponseType } from "../../types";


const handlePendingVerif = async (id: number, status: "completed" | "rejected"): Promise<UserResponseType | ErrorResponseType> => {
    try {
        const { data } = await http.put(`/admin/verif/${id}`, { status });
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const VerifController = {
    handlePendingVerif
}

export default VerifController;