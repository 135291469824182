import { Link } from "react-router-dom";
import User from "./User";
import { User as UserType } from "../../../types";

export default function Header({ user }: { user: UserType }) {
    return <>
        <div className="up-part-of-dinamic">
            <Link to="/admp/users" className="btn-s-gradient">
                <span>&lt; Назад</span>
            </Link>
        </div>
        <div className="dinamic-content -cmr-users -other-pages-content">
            <div className="list-of-users">
                <User user={user} />
            </div>
        </div>
    </>
}