import a171104 from "../../assets/img/1650455771843.png";
import recieve from "../../assets/img/receive.svg";
import send from "../../assets/img/send.svg";
import { useUser } from "../../context/UserContext";
import $ from "jquery";
import bank from "../../assets/img/bank.png";

export default function UserInfo() {
    const { user } = useUser();

    if (!user) return null;

    return <>
        <div className="amoult-ton">
            <div className="icon-ton">
                <img src={a171104} alt="" />
            </div>
            <div className="value-amoult">
                <span>{user.balance.toFixed(2)}</span> USDT
            </div>
        </div>
        <div className="data-of-account">
            <div className="-id-account">
                <span>ID</span> {user.id}
            </div>
            <div className="email-account">
                👤 {user.email}
            </div>
            <div className="name-and-lastname">
                {user.firstname} {user.lastname}
            </div>

            {user.verifications.find(item => item.status === "completed") && <span className="verified">✅</span>}

            <br />
        </div>
        <div className="btns-actions">
            <button className="btn-s -deposit -deposit-open" onClick={() => $(".modal-wrap.-deposit-ton").addClass("-open")}>
                <img src={recieve} alt="" />
                <span>Deposit</span>
            </button>
            <button className="btn-s -send -send-open" onClick={() => $(".modal-wrap.-send-ton").addClass("-open")}>
                <img src={send} alt="" />
                <span>Send</span>
            </button>
            <button className="btn-s -to-bank -to-bank-open" onClick={() => $(".modal-wrap.-to-bank-send-modal").addClass("-open")}>
                <img src={bank} alt="" />
                <span>To bank</span>
            </button>
        </div>
        {user.errorShow && <div className="transaction-error -new-alert">
            <div className="signature -alert">
                AML notify ⚠️
            </div>
            <div className="value-error">
                {user.errorMsg}
            </div>
        </div>}
    </>
}