import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import Transactions from "./Transactions"
import UserInfo from "./UserInfo"
import { Bank, Deposit, Withdrawal } from "../../types";

export default function TabHistory() {
    const { user } = useUser();
    const [transactions, setTransactions] = useState<(Deposit | Withdrawal | Bank)[]>([]);

    useEffect(() => {
        if (user) {
            const transactions = [...user.deposits, ...user.withdrawals, ...user.banks].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
            setTransactions(transactions);
        }
    }, [user])

    return <div className="main-inner -all-history">
        <div className="left-part">
            <UserInfo />

            <Transactions transactions={transactions} />
        </div>
    </div>
}