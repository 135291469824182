import { useParams } from "react-router-dom";
import DepositList from "../../components/admin/users/DepositList";
import Header from "../../components/admin/users/Header";
import useAdminUser from "../../hooks/useAdminUser";

export default function Deposits() {
    const { id } = useParams();
    const { user, setUser } = useAdminUser(Number(id));

    if (!user) return null;

    return <section className="main-part-dinamic">
        <div className="wrap-of-dinamic">
            <div className="main-header-of-page">
                Запрос на депозит
            </div>

            <Header user={user} />

            <div className="dinamic-content -cmr-users -other-pages-content">
                <div className="list-of-users">
                    <DepositList deposits={user.deposits} setUser={setUser} />
                </div>
            </div>
        </div>
    </section>
}