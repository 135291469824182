export default function Area({ title, placeholder, state, setState }: { title: string, placeholder: string, state: string, setState: React.Dispatch<React.SetStateAction<string>> }) {
    return <>
        <div className="second-others-inner-header" style={{ marginTop: "0" }}>
            {title}
        </div>

        <div className="input-wrap -valid-input">
            <textarea
                value={state}
                placeholder={placeholder}
                onChange={(e) => setState(e.target.value)}
            ></textarea>
        </div>
    </>
}