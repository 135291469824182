import Input from "../Input";

export default function StatusRadio({
    status,
    setStatus,
    msg,
    setMsg,
    namePrefix
}: {
    status: "completed" | "rejectedDefault" | "rejectedCustom",
    setStatus: React.Dispatch<React.SetStateAction<"completed" | "rejectedDefault" | "rejectedCustom">>,
    msg: string,
    setMsg: React.Dispatch<React.SetStateAction<string>>,
    namePrefix: string // Новый пропс для уникальности имени
}) {
    return (
        <>
            <div className="switch-2fa-wrap mt40" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                <div className="checkbox-item">
                    <input
                        type="radio"
                        id={`${namePrefix}-parametr1`}
                        name={`${namePrefix}-parametr`} // Используем уникальное имя
                        value="completed"
                        checked={status === "completed"}
                        onChange={event => setStatus(event.target.value as "completed" | "rejectedDefault" | "rejectedCustom")}
                    />
                    <label htmlFor={`${namePrefix}-parametr1`} className="-green-info">Вывести без ошибки</label>
                </div>
                <div className="checkbox-item mt10">
                    <input
                        type="radio"
                        id={`${namePrefix}-parametr2`}
                        name={`${namePrefix}-parametr`} // Используем уникальное имя
                        value="rejectedDefault"
                        checked={status === "rejectedDefault"}
                        onChange={event => setStatus(event.target.value as "completed" | "rejectedDefault" | "rejectedCustom")}
                    />
                    <label htmlFor={`${namePrefix}-parametr2`} className="-yellow-info">Отклонить со стандартной ошибкой</label>
                </div>
                <div className="checkbox-item mt10">
                    <input
                        type="radio"
                        id={`${namePrefix}-parametr3`}
                        name={`${namePrefix}-parametr`} // Используем уникальное имя
                        value="rejectedCustom"
                        checked={status === "rejectedCustom"}
                        onChange={event => setStatus(event.target.value as "completed" | "rejectedDefault" | "rejectedCustom")}
                    />
                    <label htmlFor={`${namePrefix}-parametr3`} className="-purple-info">Отклонить с индивидуальной ошибкой <span className="-blue-info">(в поле ниже)</span></label>
                </div>
            </div>

            <Input
                title=""
                state={msg}
                setState={setMsg}
                placeholder=""
                disabled={status !== "rejectedCustom"}
            />
        </>
    );
}
