import { UserSignInErrorResponseType, UserAuthResponseType, UserSignUpErrorResponseType } from "../types";
import http from "./index";

const signIn = async (login: string, password: string): Promise<UserAuthResponseType | UserSignInErrorResponseType> => {
    try {
        const { data } = await http.post("/users/login", {
            login,
            password,
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const signUp = async (firstname: string, lastname: string, email: string, phone: string, password: string, confirmPassword: string): Promise<UserAuthResponseType | UserSignUpErrorResponseType> => {
    try {
        const { data } = await http.post("/users/register", {
            firstname,
            lastname,
            email,
            phone,
            password,
            confirmPassword,
        });

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const signOut = async () => {
    try {
        const { data } = await http.post("/users/logout");
        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const checkAuth = async () => {
    try {
        const { data } = await http.get("/users/me");

        return data;
    } catch (error: any) {
        return error.response.data;
    }
};

const UsersAuthController = {
    signIn,
    signUp,
    signOut,
    checkAuth,
};

export default UsersAuthController;