import cancel from "../../../assets/img/cancel.svg";
import $ from "jquery";
import { copyToClipboard } from "../../../utils";
import { useUser } from "../../../context/UserContext";
import { useRef, useState } from "react";
import Input from "../../public/auth/Input";
import UsersController from "../../../api/users";
import UsersAuthController from "../../../api/auth";
import { useNavigate } from "react-router-dom";

export default function SettingsModal() {
    const { user, setUser } = useUser();
    const copyButtonRef = useRef<HTMLButtonElement>(null);
    const [inputs, setInputs] = useState({ password: "", confirmPassword: "" });
    const [messages, setMessages] = useState({
        password: "",
        confirmPassword: "",
        status: ""
    })
    const navigate = useNavigate();

    if (!user) return null;

    const handleNewPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setMessages({
            password: "",
            confirmPassword: "",
            status: ""
        })

        const response = await UsersController.changePassword(user.id, inputs.password, inputs.confirmPassword);

        setMessages(response.messages);
    };

    const handleClose = () => {
        setMessages({
            password: "",
            confirmPassword: "",
            status: ""
        })

        setInputs({
            password: "",
            confirmPassword: ""
        })

        $(".modal-wrap.-settings").removeClass("-open");
    };

    const logout = async () => {
        const response = await UsersAuthController.signOut();

        if (response.error) return;

        setUser(null);

        navigate("/");
    }

    return <div className="modal-wrap -small -settings">
        <div className="close-modal-wrap">
            <button className="close-modal" type="button" onClick={handleClose}>
                <div className="txt-btn">
                    <span>Close</span>
                    <div className="circle-txt-btn">
                        <img src={cancel} alt="" />
                    </div>
                </div>
            </button>
        </div>
        <div className="modal-inner">
            <div className="up-part-of-modal">
                <span>Setting</span>
            </div>
            <form className="inner-info-of-modal" onSubmit={handleNewPassword}>
                <div className="data-of-account">
                    <div className="-id-account">
                        <span>ID</span> {user.id} <button className="btn-s" type="button" onClick={() => {
                            copyToClipboard(user.id.toString());

                            if (copyButtonRef.current) {
                                copyButtonRef.current.innerHTML = "Copied";

                                setTimeout(() => {
                                    if (copyButtonRef.current) copyButtonRef.current.innerHTML = "Copy";
                                }, 2000);
                            }
                        }} ref={copyButtonRef} style={{ background: "#2388ff" }}>Copy</button>
                    </div>
                    <div className="email-account">
                        👤 {user.email}
                    </div>
                    <div className="name-and-lastname">
                        {user.firstname} {user.lastname}
                    </div>

                    {user.verifications.find(item => item.status === "completed") && <span className="verified">✅</span>}

                    <br />
                </div>
                <button type="button" className="txt-btn" onClick={logout}><span>Log Out
                </span><div className="circle-txt-btn">&gt;</div></button>

                <Input label="New password" placeholder="*******" required={false} type="password" state={inputs.password} onChange={(value: string) => setInputs({ ...inputs, password: value })} message={messages.password} />

                <Input label="Confirm new password" placeholder="*******" required={false} type="password" state={inputs.confirmPassword} onChange={(value: string) => setInputs({ ...inputs, confirmPassword: value })} message={messages.confirmPassword} />

                {messages.status && <div style={{ marginTop: "20px", textAlign: "center" }}>{messages.status}</div>}

                <button className="btn-s mt24" type="submit" style={{ width: "100%" }}>Save</button>
                <button className="btn-s -cancel close-btn mt8" type="button" style={{ width: "100%" }} onClick={handleClose}>Cancel</button>
            </form>

        </div>
    </div>
}