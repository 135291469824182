export default function Input({ title, state, setState, placeholder, disabled }: { title: string, state: string, setState: React.Dispatch<React.SetStateAction<string>>, placeholder: string, disabled?: boolean }) {
    return <div className="input-wrap -valid-input">
        <div className="header-of-input">{title}</div>
        <input
            type="text"
            value={state}
            placeholder={placeholder}
            onChange={(e) => setState(e.target.value)}
            disabled={disabled}
        />
    </div>
};