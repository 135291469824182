import { NavLink } from "react-router-dom"
import { useAdmin } from "../../../context/AdminContext";

export default function Nav() {
    const { signOut } = useAdmin();

    return <>
        <section className="up-part-navigation">
            <div className="mobile-menu-show">
                <ul>
                    <li></li>
                    <li className="md-line"></li>
                    <li></li>
                </ul>
            </div>
            <div className="logo-part">
                <div className="all-ads-trade-txt">
                    Навигация
                </div>
            </div>
            <div className="main-data">
                <div className="account-data">
                    <div className="theme-up profile-up-part">
                        <div className="profile-part">
                            <div className="member">Панель игры</div>
                        </div>
                    </div>
                    <div className="logout" onClick={signOut}>
                        <div className="icon-div logout-ico"></div>
                    </div>
                </div>
            </div>
        </section>
        <section className="main-part-static">
            <div className="navigation">
                <ul className="nav-part">
                    <li><NavLink to="/admp/users" className={({ isActive }) => isActive ? "active" : ""}><div className="icon-div">👤</div> <span>Пользователи</span></NavLink></li>
                    <li><NavLink to="/admp/settings" className={({ isActive }) => isActive ? "active" : ""}><div className="icon-div">⚙️</div><span>Настройки</span></NavLink></li>
                </ul>
            </div>
            <div className="decor-bg" id="particles-js">
            </div>
        </section>
    </>
}