export default function Footer() {
    return <footer className="sc-dwamxw esypfR">
        <div className="sc-BQMOd cqEFeB">
            <div className="sc-epqopy diCJg">
                <p className="sc-kUdmUV XXnQi">CoinWallet is an institution-grade, digital asset wallet for professionals. Combining robust security protocols, intuitive user interface, and multi-chain support, CoinWallet allows you to enjoy the best features of cold and hot wallets.
                </p>

            </div>
            <div className="sc-hgRQsa hzvYAK">

                <div className="sc-emIqIH eewEkz">
                    <h3 className="sc-gRtvfn kIPqnz">Wallet</h3>
                    <div className="sc-dUYKzm GhXxx">
                        <a className="sc-faUjVh gtKnPH" href="https://www.coinsdo.com/en/coinwallet-terms-of-service" target="_blank" rel="noreferrer">Terms of Service</a>
                    </div>
                </div>

            </div>
        </div>
        <div className="sc-cXPBhi eddhiW">
            <div className="sc-epqopy diCJg"><span className="sc-bhqpXc cqJFOf">Copyright© 2024 Tecstation Pte Ltd. All Rights Reserved.</span></div>
        </div>
    </footer>
}